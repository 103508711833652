
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { contactListsOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  components: {
    TmFormLine,
  },
  setup() {
    const simpleInputValue = ref('')
    const tag = ref([])

    return {
      simpleInputValue,
      contactListsOptions,
      tag,
    }
  },
})
